
<template>
  <button
    :type="type"
    class="inline-flex items-center px-3 py-1.5 bg-white text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'text-gray-400 cursor-default': disabled,
      'text-gray-700 border hover:border-red-600 hover:bg-red-600 hover:text-white focus:border-red-600 focus:bg-red-600 focus:text-white active:bg-red-700': !disabled,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ac-button-hover-danger-sm',

  props: {
    disabled: Boolean,
    type: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button',
      validator: (value: string) => ['button', 'submit'].includes(value),
    },
  },

  emits: ['click'],
})
</script>
