
<template>
  <button
    :type="type"
    class="inline-flex items-center px-2.5 py-1.5 text-sm font-medium border border-transparent leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'bg-indigo-300 cursor-default text-white': disabled,
      'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white': !disabled,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ac-button-primary-sm',

  props: {
    disabled: Boolean,
    type: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button',
      validator: (value: string) => ['button', 'submit'].includes(value),
    },
  },
  emits: ['click'],
})
</script>
