
<template>
  <nav class="flex space-x-4">
    <slot></slot>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'ac-tabs-pill',
})
</script>
