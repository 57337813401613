
<template>
  <div>
    <div class="flex" v-if="label">
      <ac-label :name="name" class="flex-grow" :class="{'text-red-700': error}">{{label}}</ac-label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="relative" :class="{ 'mt-1': label }">
      <input
        :id="name"
        :name="name"
        class="form-input shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
        :class="{
          'focus:ring-indigo-500 focus:border-indigo-500': !error,
          'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red-500': error,
          [inputClass]: true,
          'bg-gray-100 text-gray-500 cursor-not-allowed': disabled,
        }"
        :type="type"
        v-model.trim="model"
        :disabled="disabled"
        :maxlength="maxlength"
      />
      <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>exclamation-circle</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref } from 'vue'
import { requireFieldset, useSimpleModel } from '/platform/forms'

export default defineComponent({
  name: 'ac-input',

  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    rules: String,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    inputClass: {
      type: String,
      default: '',
    },
    maxlength: Number,
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-input')

    let { model, error } = useSimpleModel('ac-input', props, emit)

    return {
      model: model as Ref<string | number | undefined>,
      error,
    }
  },
})
</script>
