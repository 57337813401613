
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFileExclamation as falFileExclamation,
  faTimes as falTimes,
  faCopy as falCopy,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBars as fasBars,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faDownload as fasDownload,
  faExclamationTriangle as fasExclamationTriangle,
  faExclamationCircle as fasExclamationCircle,
  faCheckCircle as fasCheckCircle,
  faInfoCircle as fasInfoCircle,
  faTimesCircle as fasTimesCircle,
  faExternalLink as fasExternalLink,
  faH1 as fasH1,
  faLink as fasLink,
  faPencil as fasPencil,
  faPlus as fasPlus,
  faSave as fasSave,
  faSearch as fasSearch,
  faSpinner as fasSpinner,
  faTimes as fasTimes,
  faTrash as fasTrash,
  faUsersSlash as fasUsersSlash,
  faCheck as fasCheck,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faBars as farBars,
  faCreditCardBlank as farCreditCardBlank,
  faFileImage as farFileImage,
  faHome as farHome,
  faInfoCircle as farInfoCircle,
  faMailBulk as farMailBulk,
  faPenSquare as farPenSquare,
  faSensorAlert as farSensorAlert,
  faTable as farTable,
  faTasksAlt as farTasksAlt,
  faTools as farTools,
  faExternalLink as farExternalLink,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleNotch as fadCircleNotch,
  faRectangleLandscape as fadRectangleLandscape,
  faSpinnerThird as fadSpinnerThird,
} from '@fortawesome/pro-duotone-svg-icons'


library.add(
  fadCircleNotch,
  fadRectangleLandscape,
  falFileExclamation,
  falTimes,
  farBars,
  farCreditCardBlank,
  farFileImage,
  farHome,
  farInfoCircle,
  farMailBulk,
  farPenSquare,
  farSensorAlert,
  farTable,
  farTasksAlt,
  farTools,
  fasBars,
  fasChevronLeft,
  fasChevronRight,
  fasDownload,
  fasExclamationTriangle,
  fasExclamationCircle,
  fasTimesCircle,
  fasCheckCircle,
  fasInfoCircle,
  fasExternalLink,
  fasH1,
  fasLink,
  fasPencil,
  fasPlus,
  fasSave,
  fasSearch,
  fasSpinner,
  fasTimes,
  fasTrash,
  fasUsersSlash,
  falCopy,
  fasCheck,
  farExternalLink,
  fadSpinnerThird,
)
