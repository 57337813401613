
<template>
  <form
    autocomplete="off"
    novalidate
    :method="method"
    @submit="submit"
    @formdata="prepareData"
    :action="action"
    :class="{ 'inline-block': fit }"
  >
    <slot></slot>
  </form>
</template>

<script lang="ts">
import { toArray } from 'lodash-es'
import { provide, defineComponent, PropType } from 'vue'
import { Form, INJECT_FORM } from '/platform/forms'

export default defineComponent({
  name: 'ac-form',
  
  props: {
    method: {
      type: String as PropType<'get' | 'post'>,
      validator: (value: string) => ['post', 'get'].includes(value),
      default: 'post',
    },
    action: String,
    fit: Boolean,
  },

  setup(props ) {
    let form = new Form()
    provide(INJECT_FORM, form)

    return {
      submit(event: Event) {
        return form.submit(event)
      },

      // eslint-disable-next-line no-undef
      prepareData(event: FormDataEvent) {
        if (props.method === 'get') {
          // Remove empty fields from the form to avoid visual clutter.
          toArray<string>(event.formData.keys() as any).forEach(key => {
            if (!event.formData.get(key)) {
              event.formData.delete(key)
            }
          })
          return
        }

        toArray<string>(event.formData.keys() as any).forEach(key => {
          event.formData.delete(key)
        })
        event.formData.set('$value', JSON.stringify(form.serialize()))
      },
    }
  },
})
</script>
