
<template>
  <ac-input v-bind="$attrs" :maxlength="maxlength" :model-value="modelValue" @update:model-value="onChange" :name="name">
    <template #label-annotation>
      <span class="text-xs">{{size}}/{{maxlength}}</span>
    </template>
  </ac-input>
</template>

<script lang="ts">
import Input from '/components/forms/Input.vue'
import { ModelValue, requireFieldset } from '/platform/forms'
import { defineComponent, ref } from 'vue'
import { isString } from 'lodash-es'


export default defineComponent({
  name: 'ac-input-counter',

  components: {
    [Input.name]: Input,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    maxlength: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-input-counter')

    let size = ref(0)

    function onChange(value: ModelValue) {
      emit('update:modelValue', value)
      size.value = isString(value) ? value.length : 0
    }

    return {
      size,
      onChange,
    }
  },
})
</script>
