
<template>
  <nav class="bg-white px-4 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700" v-if="!info.outOfBounds">
        Resultados
        <span class="font-medium">{{info.start}}</span>
        -
        <span class="font-medium">{{info.end}}</span>
        de
        <span class="font-medium">{{info.totalSize}}</span>
      </p>
    </div>
    <div class="space-x-3 flex items-center">
      <ac-button-link-secondary :disabled="!info.prev" :href="prevPage">
        <ac-icon solid class="mr-2">chevron-left</ac-icon>
        Anterior
      </ac-button-link-secondary>
      <ac-button-link-secondary :disabled="!info.next" :href="nextPage">
        Siguiente
        <ac-icon solid class="ml-2">chevron-right</ac-icon>
      </ac-button-link-secondary>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'


interface Info {
  next: number
  prev: number
  start: number
  end: number
  totalSize: number
  outOfBounds: boolean
}

export default defineComponent({
  name: 'ac-pagination',

  props: {
    info: {
      type: Object as PropType<Info>,
      required: true,
    },
  },

  setup(props) {
    let prev = new URL(location.href)
    prev.searchParams.set('page', `${props.info.prev}`)

    let next = new URL(location.href)
    next.searchParams.set('page', `${props.info.next}`)

    return {
      prevPage: prev.toString(),
      nextPage: next.toString(),
    }
  },
})
</script>
