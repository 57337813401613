
<template>
  <template></template>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { Option, RADIO_OPTIONS } from '/platform/radios'


export default defineComponent({
  name: 'ac-radio',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    description: String,
  },
  setup(props) {
    let options = inject<Option[]>(RADIO_OPTIONS)
    options?.push({
      label: props.label,
      value: props.value,
      description: props.description,
    })
  },
})
</script>
