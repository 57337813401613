
<template>
  <div :class="{ 'w-200': !wide, 'mb-6': !fit }">
    <legend v-if="legend" class="mb-2 block text-sm font-medium text-gray-700">{{legend}}</legend>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { INJECT_FIELDSET } from '/platform/forms'

export default defineComponent({
  name: 'ac-fieldset',

  props: {
    wide: Boolean,
    legend: String,
    fit: Boolean,
  },

  setup() {
    provide(INJECT_FIELDSET, 'fieldset')
  },
})
</script>
