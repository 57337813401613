
<template>
  <a
    type="button"
    :href="href"
    class="inline-flex items-center px-4 py-2 text-white text-sm font-medium leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'bg-green-400 cursor-default': disabled,
      'bg-green-600 border border-transparent hover:bg-green-700 active:bg-green-600 hover:text-white': !disabled,
    }"
    @click="maybeStop"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-button-link-success',

  props: {
    href: {
      type: String,
      required: true,
    },

    disabled: Boolean,
  },

  setup(props) {
    return {
      maybeStop($event: Event) {
        if (props.disabled) {
          $event.preventDefault()
        }
      },
    }
  },
})
</script>
