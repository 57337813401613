
<template>
  <div>
    <div class="flex">
      <ac-label :name="name" class="flex-grow" :class="{'text-red-700': error}">{{label}}</ac-label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="flex mt-2 items-stretch space-x-2" v-for="item in items" :key="item.id">
      <div class="flex-grow">
        <ac-input :name="`${name}${item.id}`" v-model="item.edit"></ac-input>
      </div>
      <ac-button-hover-danger @click="remove(item.id)">
        <ac-icon solid>trash</ac-icon>
      </ac-button-hover-danger>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'
import { dropRightWhile } from 'lodash-es'
import { requireFieldset, useSimpleModel } from '/platform/forms'
import { nanoid } from 'nanoid'

interface Editable {
  id: string
  edit: string
}

export default defineComponent({
  name: 'ac-multiple-input',

  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default() {
        return []
      },
    },
    label: String,
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-multiple-input')

    let { model, error } = useSimpleModel('ac-multiple-input', props, emit)

    let items = ref<Editable[]>(props.modelValue?.map((edit: string) => {
      return {
        edit,
        id: nanoid(),
      }
    }))

    watch(items, () => {
      let cut = dropRightWhile(items.value, v => !v.edit)
      cut.push({ edit: '', id: nanoid() })
      if (cut.length !== items.value.length) {
        items.value = cut
      }
    }, { immediate: true, deep: true })

    watch(items, () => {
      model.value = items.value
        .map(item => item.edit)
        .filter(item => !!item)
    }, { deep: true })

    return {
      items,
      error,
      model,
      
      remove(id: string) {
        items.value = items.value.filter(item => item.id !== id)
      },
    }
  },
})
</script>
