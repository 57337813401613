
<template>
  <a
    :href="href"
    class="first:rounded-l-lg last:rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
    :class="{
      'text-indigo-700': selected,
      'text-gray-500 hover:text-gray-700': !selected,
    }"
  >
    <slot></slot>
    <span class="absolute inset-x-0 bottom-0 h-0.5" :class="{'bg-indigo-500': selected}"></span>
  </a>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { INJECT_TAB } from '/platform/tabs'


export default defineComponent({
  name: 'ac-tab-button',

  props: {
    href: String,
    selected: Boolean,
  },

  setup(props) {
    provide(INJECT_TAB, {
      selected: props.selected,
    })
  },
})
</script>
