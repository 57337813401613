
<template>
  <div class="flex items-center">
    <slot></slot>
    <button
      class="text-gray-300 hover:text-gray-500 ml-2 focus:outline-none"
      title="Copiar al portapapeles"
      @click="copy"
      v-show="!success"
    >
      <ac-icon light fixed-width class="text-xl mx-2">copy</ac-icon>
    </button>
    <button
      class="text-green-400 ml-2 focus:outline-none"
      title="¡Copiado!"
      v-show="success"
      @mouseleave="close"
    >
      <ac-icon solid fixed-width class="text-xl mx-2">check</ac-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ac-copy-text',

  props: {
    value: String,
  },
  
  setup(props) {
    let success = ref(false)
    let timeout: number

    return {
      success,

      copy() {
        if (!props.value) {
          return
        }
        
        navigator.clipboard.writeText(props.value)
        success.value = true
      },

      close() {
        if (success.value && !timeout) {
          timeout = setTimeout(() => {
            success.value = false
            timeout = 0
          }, 2000)
        }
      },
    }
  },
})
</script>
