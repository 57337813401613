
<template>
  <a
    type="button"
    :href="href"
    class="inline-flex items-center px-4 py-2 bg-white text-sm font-medium border border-gray-300 leading-5 rounded-md shadow-sm focus:outline-none"
    :class="{
      'text-gray-400 cursor-default hover:text-gray-400': disabled,
      'text-gray-700 hover:text-gray-500 active:bg-gray-50 active:text-gray-800 focus:ring-2 focus:ring-offset-2': !disabled,
    }"
    @click="maybeStop"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-button-link-secondary',

  props: {
    href: {
      type: String,
      required: true,
    },

    disabled: Boolean,
  },

  setup(props) {
    return {
      maybeStop($event: Event) {
        if (props.disabled) {
          $event.preventDefault()
        }
      },
    }
  },
})
</script>
