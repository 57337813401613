
import { isNumber, isObject, isString } from 'lodash-es'

const EMAIL_RE = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
const URL_RE = /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i
const SLUG_RE = /^[a-z\d][a-z\d-]*$/g

type Validatable = string | number | undefined | boolean | Array<string> | Date

export interface Validator {
  (value: Validatable): boolean
}

export interface ValidationRuleDefinition {
  message: (arg: string) => string
  validator: (arg: string) => Validator
}

export default {
  required: {
    message: () => `Este campo es un valor requerido`,
    validator: () => {
      return value => isObject(value) || (isNumber(value) && value) || (isString(value) && !!value.length)
    },
  },

  min: {
    message: arg => `Este campo necesita al menos ${arg} caracteres`,
    validator: arg => {
      return value => value && isString(value) && value.length >= parseInt(arg, 10)
    },
  },

  max: {
    message: arg => `Este campo puede tener como máximo ${arg} caracteres`,
    validator: arg => {
      return value => isString(value) && value.length <= parseInt(arg, 10)
    },
  },

  url: {
    message: () => `Este campo debe contener un link válido a una página o URL de Internet`,
    validator: () => {
      return value => value && isString(value) && value.match(URL_RE)
    },
  },

  email: {
    message: () => `Este campo debe ser una dirección de email válida`,
    validator: () => {
      return value => value && isString(value) && value.match(EMAIL_RE)
    },
  },

  slug: {
    message: () => `Este campo debe tener solo minúsculas, números o guiones`,
    validator: () => {
      return value => value && isString(value) && value.match(SLUG_RE)
    },
  },

  no_end_point: {
    message: () => `Este campo no puede terminar con un punto`,
    validator: () => {
      return value => value && isString(value) && !value.endsWith('.')
    },
  },

  pattern: {
    message: () => `Este campo debe cumplir con las indicaciones recomendadas en la ayuda`,
    validator: arg => {
      let re = new RegExp(arg)
      return value => value && isString(value) && value.match(re)
    },
  },

  min_value: {
    message: arg => `Este campo debe ser numérico y tiene como valor mínimo ${arg}`,
    validator: arg => {
      return value => value && value >= arg
    },
  },

  max_value: {
    message: arg => `Este campo debe ser numérico y tiene como valor máximo ${arg}`,
    validator: arg => {
      return value => value && value <= arg
    },
  },
} as { [key: string]: ValidationRuleDefinition }
