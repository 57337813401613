
<template>
  <span class="hidden" /> 
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ModelValue, useSimpleModel } from '/platform/forms'


export default defineComponent({
  name: 'ac-hidden',

  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Object, String, Number] as PropType<ModelValue>,
      default: '',
    },
  },

  setup(props, { emit }) {
    useSimpleModel('ac-hidden', props, emit)
  },
})
</script>
