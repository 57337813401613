
<template>
  <div>
    <dl>
      <slot></slot>
    </dl>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-show',
})
</script>
