
<template>
  <button
    :type="type"
    class="inline-flex items-center px-4 py-2 bg-white text-sm font-medium border border-gray-300 leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'text-gray-400 cursor-default': disabled,
      'text-gray-700 hover:text-gray-500 active:bg-gray-50 active:text-gray-800': !disabled,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ac-button-secondary',

  props: {
    disabled: Boolean,
    type: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button',
      validator: (value: string) => ['button', 'submit'].includes(value),
    },
  },
  emits: ['click'],
})
</script>
