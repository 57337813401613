
<template>
  <a
    :href="href"
    class="whitespace-nowrap flex pb-3 pt-2 px-1 border-b-2 font-medium text-sm"
    :class="{
      'border-indigo-500 text-indigo-600': selected,
      'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200': !selected,
    }"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { INJECT_TAB } from '/platform/tabs'


export default defineComponent({
  name: 'ac-tab',

  props: {
    href: String,
    selected: Boolean,
  },

  setup(props) {
    provide(INJECT_TAB, {
      selected: props.selected,
    })
  },
})
</script>
