
<template>
  <div class="odd:bg-gray-50 event:bg-white px-4 py-5 sm:flex sm:px-6">
    <dt
      class="text-sm leading-5 font-medium text-gray-500 text-right flex-shrink-0"
      :class="{
        'w-64': !fit,
        'w-36': fit,
      }"
    >
      {{name}}
    </dt>
    <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 ml-6 flex-grow">
      <slot></slot>
    </dd>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-show-pane',

  props: {
    name: {
      type: String,
      required: true,
    },
    fit: Boolean,
  },
})
</script>
