
<template>
  <div class="relative z-50 inline-block align-middle" ref="elem">
    <ac-button-hover-danger-sm :disabled="disabled" @click="toggleOpen" v-show="!running">
      <slot></slot>&#8203;
    </ac-button-hover-danger-sm>
    <ac-button-hover-danger-sm disabled v-show="running">
      <ac-icon duotone spin :class="{ 'mr-2': sending }">circle-notch</ac-icon>
      {{sending}}&#8203;
    </ac-button-hover-danger-sm>

    <Teleport to="body">
      <div
        v-if="open"
        class="absolute z-50 mt-2 bg-white border rounded shadow-lg min-w-80"
        :class="{'right-0 box-arrow-right': !left, 'box-arrow-left': left}"
        :style="pos"
      >
        <div class="px-4 py-5 sm:p-6">
          <p class="text-xl font-medium text-center">¿Está seguro de que desea<br>continuar con la operación?</p>
          <div class="mt-10 whitespace-nowrap">
            <ac-button-danger class="mr-6" @click="submit">
              <slot name="confirmation">
                <slot></slot>
              </slot>
            </ac-button-danger>

            <ac-button-secondary @click="open = false">
              <ac-icon solid class="mr-2">times</ac-icon>
              Cancelar
            </ac-button-secondary>
          </div>
        </div>
      </div>
    </Teleport>

    <form :action="action" method="post" ref="form">
      <slot name="fields"></slot>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ac-button-confirm-danger-sm',

  props: {
    disabled: Boolean,
    left: Boolean,
    sending: {
      type: String,
      default: 'Enviando...',
    },
    action: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    let open = ref(false)
    let running = ref(false)
    let pos = ref({})
    let elem = ref<HTMLDivElement>()
    let form = ref<HTMLFormElement>()

    return {
      open,
      running,
      pos,
      elem,
      form,

      submit() {
        open.value = false
        running.value = true
        form.value?.submit()
      },

      toggleOpen() {
        if (!elem.value) {
          return
        }
        
        if (!open.value) {
          let { bottom, left, right } = elem.value.getBoundingClientRect()
          if (props.left) {
            pos.value = {
              left: `${left}px`,
              top: `${bottom + window.scrollY}px`,
            }
          } else {
            pos.value = {
              right: `${window.innerWidth - right}px`,
              top: `${bottom + window.scrollY}px`,
            }
          }
        }

        open.value = !open.value
      },
    }
  },
})
</script>
