
import { createApp } from 'vue'

import { register } from '../lib'
import '/styles/demo.css'

import Demo from './Demo.vue'
import DemoFull from './DemoFull.vue'
import Showcase from './Showcase.vue'
import HomeLogo from './HomeLogo.vue'


let app = createApp({})

register(app)

app.component(Showcase.name, Showcase)
app.component(Demo.name, Demo)
app.component(DemoFull.name, DemoFull)
app.component(HomeLogo.name, HomeLogo)

app.mount('main')
