
<template>
  <Card fit>
    <template #title>{{title}}</template>
    <template #description>{{description}}</template>
    <template #actions>
      <div class="text-right">
        <code class="text-sm">
          <slot name="components">{{components}}</slot>
        </code>
      </div>
    </template>
    <template #content>
      <div
        class="bg-blue-gray-100 inline-block w-full"
        :class="{
          'p-12': !fit,
          'px-12': fit,
        }"
      >
        <slot></slot>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Card from '/components/cards/Card.vue'

export default defineComponent({
  name: 'ac-demo-full',

  components: {
    Card,
  },

  props: {
    title: String,
    description: String,
    components: String,
    fit: Boolean,
  },
})
</script>
