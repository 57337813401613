
<template>
  <label :for="name" class="block text-sm font-medium text-gray-700">
    <slot></slot>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-label',

  props: {
    name: String,
  },
})
</script>
