
<template>
  <div class="flex flex-col flex-grow pt-5 pb-4 bg-logo overflow-y-auto">
    <div class="flex items-center flex-shrink-0 px-8 py-4">
      <img class="w-auto" src="/images/logo.svg" />
    </div>
    <div class="mt-5 flex-grow flex flex-col">
      <nav class="flex-1 px-2 space-y-1 bg-logo">
        <template v-for="item in items" :key="item.name">
          <div v-if="!item.children">
            <a :href="item.to" :class="[isCurrent(item) ? 'bg-blue-gray-200 text-gray-900 hover:text-gray-900' : 'bg-logo text-gray-600 hover:bg-blue-gray-200 hover:text-gray-900', 'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md']">
              <ac-icon
                fixed-width
                :variant="item.variant"
                :class="[isCurrent(item) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
              >
                {{item.icon}}
              </ac-icon>
              {{item.name}}
            </a>
          </div>
          <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }" :default-open="!!item.children.find(child => isCurrent(child))">
            <DisclosureButton :class="[isCurrent(item) ? 'bg-gray-100 text-gray-900' : 'bg-logo text-gray-600 hover:bg-blue-gray-200 hover:text-gray-900', 'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500']">
              <ac-icon
                fixed-width
                :variant="item.variant"
                class="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
              >
                {{item.icon}}
              </ac-icon>
              <span class="flex-1">
                {{item.name}}
              </span>
              <svg :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']" viewBox="0 0 20 20">
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </DisclosureButton>
            <DisclosurePanel class="space-y-1">
              <a
                v-for="subitem in item.children"
                :key="subitem.name"
                :href="subitem.to"
                class="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md"
                :class="{
                  'bg-blue-gray-200 text-gray-900 hover:text-gray-900': isCurrent(subitem),
                  'bg-logo text-gray-600 hover:bg-blue-gray-200 hover:text-gray-900': !isCurrent(subitem),
                }"
              >
                {{subitem.name}}
              </a>
            </DisclosurePanel>
          </Disclosure>
        </template>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineComponent } from 'vue'


interface SidebarItem {
  name: string
  icon: string
  variant: 'solid' | 'regular' | 'light' | 'duotone'
  to?: string
  children?: SidebarSubitem[]
}

interface SidebarSubitem {
  name: string
  to: string
}

const NAVIGATION: SidebarItem[] = [
  {
    name: 'Home',
    icon: 'home',
    variant: 'regular',
    to: '/',
  },
  {
    name: 'Cabecera',
    icon: 'h1',
    variant: 'solid',
    to: '/header',
  },
  {
    name: 'Tablas',
    icon: 'table',
    variant: 'regular',
    children: [
      { name: 'Simple', to: '/tables/simple' },
      { name: 'Con tarjeta', to: '/cards/table' },
      { name: 'Input de búsqueda', to: '/forms/search' },
    ],
  },
  {
    name: 'Tarjetas',
    icon: 'credit-card-blank',
    variant: 'regular',
    children: [
      { name: 'Simple', to: '/cards/simple' },
      { name: 'Con tabla', to: '/cards/table' },
      { name: 'Con ShowPane', to: '/cards/show-pane' },
    ],
  },
  {
    name: 'Formularios',
    icon: 'pen-square',
    variant: 'regular',
    children: [
      { name: 'Estructura general', to: '/forms/structure' },
      { name: 'Botón de envío', to: '/forms/submit' },
      { name: 'Input', to: '/forms/input' },
      { name: 'Textarea', to: '/forms/textarea' },
      { name: 'Selector', to: '/forms/select' },
      { name: 'Checkbox', to: '/forms/checkbox' },
      { name: 'Radio', to: '/forms/radio' },
      { name: 'Lista dual', to: '/forms/dual-list' },
      { name: 'Selector de fechas', to: '/forms/datepicker' },
      { name: 'Input de búsqueda', to: '/forms/search' },
      { name: 'Input múltiple', to: '/forms/multiple-input' },
      { name: 'Mensaje de error', to: '/forms/global-err' },
    ],
  },
  {
    name: 'Botones',
    icon: 'rectangle-landscape',
    variant: 'duotone',
    children: [
      { name: 'Simples', to: '/buttons/simple' },
      { name: 'Con confirmación', to: '/buttons/confirmation' },
      { name: 'Envío de formularios', to: '/forms/submit' },
    ],
  },
  {
    name: 'Pestañas',
    icon: 'tasks-alt',
    variant: 'regular',
    children: [
      { name: 'Navegación', to: '/tabs/navigation' },
    ],
  },
  {
    name: 'Hojas laterales',
    icon: 'sensor-alert',
    variant: 'regular',
    children: [
      { name: 'Formulario', to: '/side-sheets/form' },
      { name: 'SheetEdit / SheetCreate', to: '/side-sheets/sheet-edit' },
    ],
  },
  {
    name: 'Badges y alertas',
    icon: 'info-circle',
    variant: 'regular',
    children: [
      { name: 'Badges', to: '/alerts/badge' },
      { name: 'Alertas', to: '/alerts/alert' },
    ],
  },
  {
    name: 'Ficheros y fotos',
    icon: 'file-image',
    variant: 'regular',
    children: [
      { name: 'Selector de ficheros', to: '/files/file-selector' },
      { name: 'Avatar', to: '/files/generated-avatar' },
    ],
  },
  {
    name: 'Utilidades',
    icon: 'tools',
    variant: 'regular',
    children: [
      { name: 'Links externos', to: '/utilities/external-link' },
      { name: 'Copiar el texto', to: '/utilities/copy-text' },
    ],
  },
]

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },

  setup() {
    return {
      items: NAVIGATION,

      isCurrent(item: { to?: string }) {
        return item.to === location.pathname
      },
    }
  },
})
</script>
