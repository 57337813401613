
<template>
  <div>
    <slot />
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 flex-grow"
        :class="{'text-red-700': error}"
      >{{label}}</label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="mt-1">
      <RadioGroup v-model="model">
        <div class="bg-white rounded-md -space-y-px">
          <RadioGroupOption as="template" v-for="(option, index) in allOptions" :key="option.value" :value="option.value" v-slot="{ checked, active }">
            <div
              :class="{
                'rounded-tl-md rounded-tr-md': index === 0,
                'rounded-bl-md rounded-br-md': index === allOptions.length - 1,
                'bg-indigo-50 border-indigo-200 z-10': checked,
                'border-gray-200': !checked,
              }"
              class="relative border p-4 flex cursor-pointer focus:outline-none"
            >
              <span
                :class="{
                  'bg-indigo-600 border-transparent': checked,
                  'bg-white border-gray-300': !checked,
                  'ring-2 ring-offset-2 ring-indigo-500': active,
                }"
                class="h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
              >
                <span class="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <div class="ml-3 flex flex-col">
                <RadioGroupLabel
                  as="span"
                  :class="{
                    'text-indigo-900': checked,
                    'text-gray-900': !checked,
                  }"
                  class="block text-sm font-medium"
                >
                  {{option.label}}
                </RadioGroupLabel>
                <RadioGroupDescription
                  v-if="option.description"
                  as="span"
                  :class="{
                    'text-indigo-700': checked,
                    'text-gray-500': !checked,
                  }"
                  class="block text-sm"
                >
                  {{option.description}}
                </RadioGroupDescription>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, provide, reactive, toRefs, watch } from 'vue'
import { requireFieldset, useSimpleModel } from '/platform/forms'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { Option, RADIO_OPTIONS } from '/platform/radios'


export default defineComponent({
  name: 'ac-radio-panel',

  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    options: Array as PropType<Array<Option>>,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-radio-panel')

    let { model, error } = useSimpleModel('ac-radio-panel', props, emit)

    let allOptions = reactive<Option[]>([])
    provide(RADIO_OPTIONS, allOptions)

    watch(toRefs(props).options, () => {
      allOptions.splice(0, allOptions.length)
      if (props.options) {
        allOptions.push(...props.options)
      }
    }, { immediate: true })

    return {
      model,
      error,
      allOptions,
    }
  },
})
</script>
