
<template>
  <th
    class="py-3 font-medium text-gray-500 tracking-wider whitespace-nowrap"
    :class="{
      'text-left': !right,
      'text-right': right,
      'text-xs uppercase': !conserveCasing,
      'text-sm': conserveCasing,
      'pl-6': fit === 'right',
      'pr-6': fit === 'left',
      'px-6': fit === 'none',
      '': fit === 'both',
    }"
  >
    <slot></slot>
  </th>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, onUnmounted } from 'vue'
import { INJECT_TABLE } from './Table.vue'

export default defineComponent({
  name: 'ac-cell-header',

  props: {
    right: Boolean,
    conserveCasing: Boolean,
    fit: {
      type: String,
      default: 'none',
    },
  },

  setup() {
    let table = inject(INJECT_TABLE)

    onMounted(() => {
      if (!table) {
        throw new Error(`ac-cell-header can only be used inside ac-table`)
      }
      table.cols++
    })
    
    onUnmounted(() => {
      if (!table) {
        throw new Error(`ac-cell-header can only be used inside ac-table`)
      }
      table.cols--
    })
  },
})
</script>
