
<template>
  <component :is="button" @click="reset" :disabled="disabled">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useForm } from '/platform/forms'

export default defineComponent({
  name: 'ac-reset',

  emits: ['click'],

  props: {
    disabled: Boolean,
    button: {
      type: String,
      default: 'ac-button-secondary',
    },
  },

  setup(_props, { emit }) {
    let form = useForm('ac-reset')

    return {
      reset() {
        form.reset()
        emit('click')
      },
    }
  },
})
</script>
