
<template>
  <ac-card fit>
    <template #title v-if="$slots.title && $slots.title().length">
      <slot name="title"></slot>
    </template>
    <template #description v-if="$slots.description && $slots.description().length">
      <slot name="description"></slot>
    </template>
    <template #actions v-if="$slots.actions && $slots.actions().length">
      <slot name="actions"></slot>
    </template>
    <template #footer v-if="$slots.footer && $slots.footer().length">
      <slot name="footer"></slot>
    </template>
    <template #band v-if="$slots.band && $slots.band().length">
      <slot name="band"></slot>
    </template>
    
    <template #content>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <slot name="header"></slot>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <slot name="rows"></slot>

          <tr v-if="!table.rows">
            <td :colspan="table.cols" class="bg-white text-center text-xl italic text-gray-400 py-20">
              {{empty}}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ac-card>
</template>

<script lang="ts">
import { defineComponent, provide, reactive } from 'vue'
import { INJECT_TABLE } from '/components/table/Table.vue'


export default defineComponent({
  name: 'ac-card-table',

  props: {
    empty: {
      type: String,
      required: true,
    },
  },

  setup() {
    let table = reactive({
      rows: 0,
      cols: 0,
    })
    provide(INJECT_TABLE, table)

    return { table }
  },
})
</script>
