
import { App } from 'vue'
import { Icon } from '@altipla/fa6-icon'

import '/styles/global.css'
import './icons'

export { requireFieldset, useForm, useSimpleModel } from '/platform/forms'
export type { ModelValue } from '/platform/forms'


const modules = (import.meta as any).globEager('./components/**/*.vue')

export function register(app: App): void {
  app.component(Icon.name, Icon)
  
  Object.values(modules)
    .map((mod: any) => mod.default)
    .forEach(mod => app.component(mod.name, mod))
}
