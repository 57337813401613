
<template>
  <img class="mx-36 my-8" src="/images/logo.svg" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-home-logo',
})
</script>
