
<template>
  <tr class="odd:bg-white even:bg-gray-50">
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, onUnmounted } from 'vue'
import { INJECT_TABLE } from './Table.vue'

export default defineComponent({
  name: 'ac-row',

  setup() {
    let table = inject(INJECT_TABLE)

    onMounted(() => {
      if (!table) {
        throw new Error(`ac-row can only be used inside ac-table`)
      }
      table.rows++
    })

    onUnmounted(() => {
      if (!table) {
        throw new Error(`ac-row can only be used inside ac-table`)
      }
      table.rows--
    })
  },
})
</script>
