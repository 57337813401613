
import { cloneDeep, identity, isEqual, some } from 'lodash-es'
import { InjectionKey, inject, computed, Ref, ref, onUnmounted, toRefs, watch, ComputedRef } from 'vue'
import VALIDATIONS, { Validator } from '/platform/validations'


export let INJECT_FIELDSET: InjectionKey<string> = Symbol('fieldset')
export let INJECT_FORM: InjectionKey<Form> = Symbol('form')

export function requireFieldset(componentName: string): void {
  if (!inject(INJECT_FIELDSET)) {
    throw new Error(`${componentName} needs a ac-fieldset around it`)
  }
}

interface ValidationRule {
  name: string
  message: string
  validator: Validator
}

function parseRules(rules: string): ValidationRule[] {
  if (!rules) {
    return []
  }
  return rules
    .split('|')
    .map(rule => {
      let [name, arg] = rule.split(':')
      if (!VALIDATIONS[name]) {
        throw new Error(`unknown form validation rule: ${rule}`)
      }

      return {
        name,
        message: VALIDATIONS[name].message(arg),
        validator: VALIDATIONS[name].validator(arg),
      }
    })
}

export type ModelValue = string | number | undefined | boolean | Array<string> | Date

interface Field {
  name: string
  model: Ref<ModelValue>
  errors: Ref<string | undefined>
  rules: ValidationRule[]
  reset: () => void
}

interface FieldMount {
  name: string
  model: Ref<ModelValue>
  rules: string
  reset: () => void
}

export class Form {
  touched: Ref<boolean>
  sending: Ref<boolean>
  globalError: Ref<string>
  submitted: Ref<boolean>
  private fields: Map<string, Field>
  private initialValues: Map<string, ModelValue>

  constructor() {
    this.fields = new Map()
    this.initialValues = new Map()

    this.submitted = ref(false)
    this.sending = ref(false)
    this.touched = ref(false)
    this.globalError = ref('')
  }

  mount(field: FieldMount): Field {
    if (this.fields.has(field.name)) {
      throw new Error(`field ${field.name} is already registered in the form`)
    }

    this.fields.set(field.name, {
      name: field.name,
      model: field.model,
      errors: ref(),
      rules: parseRules(field.rules),
      reset: field.reset,
    })

    this.initialValues.set(field.name, cloneDeep(field.model.value))
    return this.fields.get(field.name) as Field
  }

  unmount(name: string): void {
    this.fields.delete(name)
  }

  validate(name: string): void {
    const field = this.fields.get(name)
    if (!field) {
      throw new Error(`cannot find field ${name} to validate`)
    }

    let failed = field.rules.find(rule => !rule.validator(field.model.value))
    field.errors.value = failed ? failed.message : undefined
  }

  field(name: string): Field | undefined {
    return this.fields.get(name)
  }

  initialValue(name: string): ModelValue {
    return this.initialValues.get(name)
  }

  get hasErrors(): ComputedRef<boolean> {
    return computed(() => this.submitted.value && some(Array.from(this.fields.values()), field => field.errors.value))
  }

  async submit(event: Event): Promise<void> {
    this.globalError.value = ''
    this.submitted.value = true

    // Revalidate all fields
    for (let name of this.fields.keys()) {
      this.validate(name)
    }

    if (this.hasErrors.value) {
      event.preventDefault()
      // TODO(ernesto): Scrollar al primer error visible.
      return
    }

    this.sending.value = true
  }

  serialize(): { [key: string]: any } {
    let val: { [key: string]: any } = {}
    for (let name of this.fields.keys()) {
      val[name] = this.fields.get(name)?.model.value
    }
    return val
  }

  reset(): void {
    this.globalError.value = ''
    this.sending.value = false
    this.submitted.value = false
    this.touched.value = false
    for (let field of this.fields.values()) {
      field.reset()
    }
  }
}

export function useForm(componentName: string): Form {
  let form = inject(INJECT_FORM)
  if (!form) {
    throw new Error(`${componentName} needs a ac-form around it`)
  }
  return form
}

interface FieldProps {
  name: string
  rules?: string
  modelValue: ModelValue
}

interface EmitFn {
  (event: 'update:modelValue', ...args: any[]): void
}

interface SimpleModelOptions {
  parseModel?: (s: ModelValue) => ModelValue
  serializeModel?: (s: ModelValue) => ModelValue
  model?: Ref<ModelValue>
}

interface SimpleModel {
  model: Ref<ModelValue>
  error: Ref<string | false | undefined>
}

export function useSimpleModel(componentName: string, props: FieldProps, emit: EmitFn, opts: SimpleModelOptions = {}): SimpleModel {
  let parseModel = opts.parseModel || identity
  let serializeModel = opts.serializeModel || identity

  let form = useForm(componentName)

  let field = form.mount({
    name: props.name,
    model: opts.model || ref(parseModel(props.modelValue)),
    rules: props.rules || '',
    reset: () => {
      field.model.value = form.initialValue(props.name)
    },
  })

  watch(toRefs(props).modelValue, () => {
    if (isEqual(props.modelValue, serializeModel(field.model.value))) {
      return
    }

    let updated = parseModel(props.modelValue)
    field.model.value = updated
    form.validate(props.name)
  })

  watch(field.model, () => {
    if (isEqual(serializeModel(field.model.value), props.modelValue)) {
      return
    }

    form.touched.value = true
    emit('update:modelValue', serializeModel(field.model.value))
    form.validate(props.name)
  })

  onUnmounted(() => {
    form.unmount(props.name)
  })

  return {
    model: field.model,
    error: computed(() => form.submitted.value && field.errors.value),
  }
}
