
<template>
  <div class="p-4 border-l-4 border-red-400 bg-red-50">
    <div class="flex" :class="{'items-center': !$slots.content}">
      <div class="flex-shrink-0 mr-2 text-red-400" v-if="$slots.icon">
        <slot name="icon"></slot>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-900" v-if="$slots.title">
          <slot name="title"></slot>
        </h3>
        <div class="mt-2 text-sm text-red-800" v-if="$slots.content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-alert-error',
})
</script>
