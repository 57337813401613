
export function hideBodyScroll(): void {
  if (document.body.classList.contains('force-hide-scroll')) {
    return
  }
  let scrollbarWidth = window.innerWidth - document.body.clientWidth
  document.body.style.paddingRight = `${scrollbarWidth}px`
  document.body.classList.add('force-hide-scroll')
}

export function showBodyScroll(): void {
  document.body.style.paddingRight = '0'
  document.body.classList.remove('force-hide-scroll')
}
