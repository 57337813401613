
<template>
  <div class="inline-block">
    <component :is="button" :disabled="working" v-show="working">
      <slot name="working">
        <ac-icon duotone spin>spinner-third</ac-icon>
        <span class="ml-3">Enviando fichero...</span>
      </slot>
    </component>
    <component :is="button" v-show="!working" class="relative">
      <label :for="name" class="absolute inset-0 cursor-pointer"></label>
      <slot>Seleccione el fichero</slot>
    </component>

    <form :action="action" method="post" enctype="multipart/form-data" class="sr-only" ref="form">
      <input
        type="file"
        :name="name"
        :id="name"
        @change="fileChanged"
        :accept="accept"
        ref="file"
      >
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ac-file-selector',

  props: {
    name: {
      type: String,
      required: true,
    },
    accept: String,
    action: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      default: 'ac-button-primary',
    },
  },

  setup() {
    let working = ref(false)
    let form = ref<HTMLFormElement>()

    return {
      working,
      form,

      fileChanged() {
        working.value = true
        form.value?.submit()
      },
    }
  },
})
</script>
