
<template>
  <Card>
    <template #title>{{title}}</template>
    <template #description>{{description}}</template>
    <template #actions>
      <code>{{components}}</code>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Card from '/components/cards/Card.vue'


export default defineComponent({
  name: 'ac-demo',
  
  components: {
    Card,
  },

  props: {
    title: String,
    description: String,
    components: String,
  },
})
</script>
