
<template>
  <div class="h-screen flex overflow-hidden bg-logo">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <ac-icon light class="text-white">times</ac-icon>
                </button>
              </div>
            </TransitionChild>
            <DemoSidebar></DemoSidebar>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </TransitionRoot>

    <div class="hidden bg-indigo-700 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <DemoSidebar></DemoSidebar>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <ac-icon solid>bars</ac-icon>
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-scroll focus:outline-none">
        <div class="max-w-5xl pb-32 mx-auto mt-8">
          <div class="space-y-8">
            <slot></slot>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { defineComponent, ref } from 'vue'
import DemoSidebar from './DemoSidebar.vue'


export default defineComponent({
  name: 'ac-showcase',
  
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    DemoSidebar,
  },

  setup() {
    let sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
})
</script>
