
<template>
  <div>
    <ac-label :name="name" class="flex-grow" :class="{'text-red-700': error}">{{label}}</ac-label>
    <div class="mt-1">
      <input
        class="form-input shadow-sm block sm:text-sm border-gray-300 rounded-md"
        :class="{
          'cursor-pointer': !disabled,
          'bg-gray-100 text-gray-500 !cursor-not-allowed': disabled,
        }"
        type="text"
        :id="name"
        :name="name"
        ref="input"
        :disabled="disabled"
        readonly
      >
      <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>exclamation-circle</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import flatpickr from 'flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Instance } from 'flatpickr/dist/types/instance'
import { isString } from 'lodash-es'
import { onMounted, PropType, ref, watch } from 'vue'
import { parseISO } from 'date-fns'
import { requireFieldset, useSimpleModel, ModelValue } from '/platform/forms'


export default defineComponent({
  name: 'ac-datepicker',

  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    rules: String,
    modelValue: {
      type: [Object, String] as PropType<Date | string>,
      default: null,
    },
    disabled: Boolean,
    min: Object as PropType<Date>,
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-datepicker')

    let { model, error } = useSimpleModel('ac-datepicker', props, emit, {
      parseModel(s: ModelValue): ModelValue {
        if (isString(s)) {
          return parseISO(s)
        }
        return s
      },
    })

    let input = ref<HTMLInputElement>()

    let picker: Instance
    onMounted(() => {
      cleanMin()
      picker = flatpickr(input.value as Node, {
        dateFormat: 'd M Y',
        minDate: props.min,
        defaultDate: props.modelValue,
        locale: Spanish,
        onChange: dates => {
          model.value = dates[0]
        },
      })
    })

    watch(() => props.modelValue, () => {
      if (!picker) {
        return
      }
      picker.setDate(props.modelValue)
    })

    watch(() => props.min, () => {
      cleanMin()
      picker.set('minDate', props.min)
      if (props.min && props.min > picker.selectedDates[0]) {
        picker.setDate(props.min)
      }
    })

    function cleanMin() {
      if (!props.min) {
        return
      }

      props.min.setHours(0)
      props.min.setMinutes(0)
      props.min.setSeconds(0)
      props.min.setMilliseconds(0)
    }

    return {
      error,
      input,
    }
  },
})
</script>
