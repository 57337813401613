
<template>
  <ac-label>
    <input
      type="checkbox"
      class="form-checkbox h-4 w-4 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      :id="name"
      :name="name"
      :value="value"
      v-model="model"
    >
    {{label}}
  </ac-label>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref } from 'vue'
import { requireFieldset, useSimpleModel } from '/platform/forms'

export default defineComponent({
  name: 'ac-checkbox',

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: 'true',
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Boolean, Array] as PropType<boolean | Array<string>>,
      default: false,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    requireFieldset('ac-checkbox')

    let { model } = useSimpleModel('ac-checkbox', props, emit)
    return {
      model: model as Ref<boolean | undefined>,
    }
  },
})
</script>
