
<template>
  <div class="border-b border-gray-200">
    <nav class="-mb-px flex space-x-8">
      <slot></slot>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'ac-tabs',
})
</script>
