
<template>
  <div class="relative">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <span class="text-gray-300">
        <ac-icon solid>search</ac-icon>
      </span>
    </div>
    <input
      type="text"
      :id="name"
      :name="name"
      class="form-input block py-2 pl-10 pr-3 text-sm placeholder-gray-500 bg-white border border-gray-300 w-96 shadow-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      :placeholder="placeholder"
      @keypress.enter="send"
      v-model="val"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ac-search-input',

  props: {
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: String,
  },

  setup(props) {
    let val = ref(props.value)

    return {
      val,
      send() {
        let u = new URL(location.href)
        u.searchParams.delete('page')
        if (val.value) {
          u.searchParams.set('q', val.value)
        } else {
          u.searchParams.delete('q')
        }
        location.href = u.toString()
      },
    }
  },
})
</script>
