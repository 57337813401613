
<template>
  <td
    class="text-sm text-gray-500"
    :class="{
      'text-right': right,
      'font-medium': bold,
      'whitespace-nowrap': !wrap,
      'w-1': collapse,
      'pl-6': fit === 'right',
      'pr-6': fit === 'left',
      'px-6': fit === 'none',
      '': fit === 'both',
      'py-4': !actions,
      'align-middle': actions,
    }"
    :colspan="colspan"
  >
    <slot></slot>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-cell',

  props: {
    // Alinea el contenido de la celda a la derecha.
    right: Boolean,

    // Utiliza espaciado lateral más pequeño.
    fit: {
      type: String,
      default: 'none',
    },

    // Restringe el espaciado vertical para meter botones pequeños más fácilmente.
    actions: Boolean,

    // Negrita en el texto de la celda.
    bold: Boolean,

    // Activa los saltos de línea. Por defecto están desactivados y se fuerza
    // a que esté todo en una sola línea aunque sobrepase la anchura de la tabla.
    wrap: Boolean,

    // Colapsa el ancho al mínimo posible. Se recomienda usar esta opción conjuntamente
    // con no activar wrap, para evitar saltos de línea inesperados.
    collapse: Boolean,

    colspan: Number,
  },
})
</script>
