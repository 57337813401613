
<template>
  <div class="mb-8 mt-6">
    <div v-if="back">
      <nav class="sm:hidden">
        <a :href="back" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
          <ac-icon solid class="mr-2 text-gray-400">chevron-left</ac-icon>
          Atrás
        </a>
      </nav>
      <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
        <slot name="breadcrumbs"></slot>
      </nav>
    </div>
    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {{title}}
        </h2>
      </div>
      <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { watch, ref } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-header',

  setup(props, { slots }) {
    function extractContent() {
      if (!slots.breadcrumbs) {
        return { back: '', title: '[ERROR]' }
      }

      let breadcrumbs = slots.breadcrumbs().filter(breadcrumb => (breadcrumb.type as any).name === 'ac-breadcrumb')
      if (breadcrumbs.length < 1) {
        throw new Error(`missing breadcrumbs required to build urls`)
      }
      
      return {
        back: breadcrumbs.length > 1 && (breadcrumbs[breadcrumbs.length - 2] as any).props.url,
        title: (breadcrumbs[breadcrumbs.length - 1] as any).children.default()[0].children,
      }
    }

    let content = extractContent()
    let back = ref(content.back)
    let title = ref(content.title)

    watch(extractContent, content => {
      back.value = content.back
      title.value = content.title
    })

    return { back, title }
  },
})
</script>
