
<template>
  <a
    :href="href"
    class="px-3 py-2 font-medium text-sm rounded-md flex"
    :class="{
      'bg-indigo-100 text-indigo-700': selected,
      'text-gray-500 hover:text-gray-700': !selected,
    }"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { INJECT_TAB } from '/platform/tabs'


export default defineComponent({
  name: 'ac-tab-pill',

  props: {
    href: String,
    selected: Boolean,
  },

  setup(props) {
    provide(INJECT_TAB, {
      selected: props.selected,
    })
  },
})
</script>
