
<template>
  <span class="inline-flex items-center justify-center rounded-full" :class="bgColor">
    <span class="font-medium leading-none text-white" :class="textClass">{{initials}}</span>
  </span>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'

const COLORS = [
  'bg-red-300',
  'bg-yellow-300',
  'bg-green-300',
  'bg-blue-300',
  'bg-indigo-300',
  'bg-purple-300',
  'bg-pink-300',
]


export default defineComponent({
  name: 'ac-generated-avatar',

  props: {
    name: String,

    textClass: {
      type: String,
      default: 'text-sm',
    },
  },

  setup(props) {
    let initials = ref('')
    let bgColor = ref('')
    
    function updateAvatar() {
      let name = props.name || ''
      let parts = name.split(' ')
      
      initials.value = `${parts[0].charAt(0)}${parts[0].charAt(1)}`.toLocaleUpperCase()
      if (parts.length > 1) {
        initials.value = `${parts[0].charAt(0)}${parts[1].charAt(0)}`.toLocaleUpperCase()
      }

      let charCodes = initials.value
        .split('')
        .map(char => char.charCodeAt(0))
        .join('')
      bgColor.value = COLORS[parseInt(charCodes, 10) % COLORS.length]
    }

    watch(() => props.name, updateAvatar)
    onMounted(updateAvatar)

    return { initials, bgColor }
  },
})
</script>
