
<template>
  <span
    class="hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
    :class="{
      'bg-indigo-200 text-indigo-600': tab?.selected,
      'bg-gray-200 text-gray-700': !tab?.selected,
    }"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { INJECT_TAB } from '/platform/tabs'


export default defineComponent({
  name: 'ac-tab-badge',

  setup() {
    return {
      tab: inject(INJECT_TAB),
    }
  },
})
</script>
