
<template>
  <slot name="trigger" :open="open"></slot>

  <teleport to="body">
    <div class="fixed inset-0 overflow-hidden z-50" v-show="dialog">
      <div class="absolute inset-0 overflow-hidden">
        <transition
          enter-active-class="ease-in-out duration-500"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in-out duration-500"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
          appear
        >
          <div
            v-show="isOpen"
            @click="close"
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          ></div>
        </transition>
        
        <div class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <transition
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
            appear
            @after-leave="closed"
          >
            <div class="w-screen max-w-2xl" v-show="isOpen">
              <ac-form :action="action" :method="method" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <h2 class="text-lg font-medium text-white">
                      <slot name="title"></slot>
                    </h2>
                    <div class="ml-3 h-6 flex items-center">
                      <button type="button" class="text-indigo-200 hover:text-white px-3 text-xl focus:outline-none" @click="close">
                        <ac-icon light>times</ac-icon>
                      </button>
                    </div>
                  </div>
                  <p class="text-sm text-indigo-200 mt-1" v-if="$slots.subtitle">
                    <slot name="subtitle"></slot>
                  </p>
                </div>
                <div class="min-h-0 flex-1 flex flex-col overflow-y-scroll">
                  <slot name="content"></slot>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <slot name="buttons">
                    <div class="space-x-3 w-full flex justify-between">
                      <ac-reset @click="close">
                        <slot name="cancel">Cancelar</slot>
                      </ac-reset>                      
                      <slot name="submit"></slot>
                    </div>
                  </slot>
                </div>
              </ac-form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { hideBodyScroll, showBodyScroll } from '/platform/dom'

export default defineComponent({
  name: 'ac-side-sheet-form',

  props: {
    method: String,
    action: String,
    defaultsOpen: Boolean,
    cancel: String,
  },

  setup(props) {
    let isOpen = ref(false)
    let dialog = ref(props.defaultsOpen)

    let listener = (e: KeyboardEvent) => {
      if (isOpen.value && e.key == 'Escape') {
        isOpen.value = false
      }
    }
    window.addEventListener('keydown', listener)

    onMounted(() => {
      if (props.defaultsOpen) {
        isOpen.value = true
      }
    })

    onBeforeUnmount(() => {
      showBodyScroll()
      if (listener) {
        window.removeEventListener('keydown', listener)
      }
    })

    watch(isOpen, () => {
      if (isOpen.value) {
        hideBodyScroll()
        dialog.value = true
      }
    })
    
    return {
      isOpen,
      dialog,

      open() {
        isOpen.value = true
      },

      close() {
        if (props.cancel) {
          location.href = props.cancel
        } else {
          isOpen.value = false
        }
      },

      closed() {
        dialog.value = false
        showBodyScroll()
      },
    }
  },
})
</script>
